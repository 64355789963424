// ░█▀▀▄ ░█▀▀▀█ 　 ░█▄─░█ ░█▀▀▀█ ▀▀█▀▀ 　 ░█▀▀▀ ░█▀▀▄ ▀█▀ ▀▀█▀▀
// ░█─░█ ░█──░█ 　 ░█░█░█ ░█──░█ ─░█── 　 ░█▀▀▀ ░█─░█ ░█─ ─░█──
// ░█▄▄▀ ░█▄▄▄█ 　 ░█──▀█ ░█▄▄▄█ ─░█── 　 ░█▄▄▄ ░█▄▄▀ ▄█▄ ─░█──
// The component is made to be used flexibly. But if you REALLY MUST update edit the component,
// please add `-custom` on the change log file (e.g. `1.0.0-custom.md`) and log the changes

// ========================================================================================================================================
// Nesletter
// ========================================================================================================================================
import React, { useState } from "react"

//Utils
import PropTypes from "prop-types"
import { initializeApollo } from 'lib/apollo'; //!Remove if not use for ecommerce project
import { M_SUBSCRIBE } from 'lib/graphql';  //!Remove if not use for ecommerce project

//Components
import { Input, Button } from "components/anti"
import MailchimpSubscribe from "react-mailchimp-subscribe"

export const NewsLetter = ({
   fn, //Callback function that will returns data/errors
   className,
   inputClassName,
   btnClassName,
   btnVariant,
   btnWrapperClassName,
   query //If the functions not from plugin just set query to true (Boolean)
}) => {

   //States
   const handleSubscribe = (e, email, subscribe) => {
      e.preventDefault()
      subscribe({ EMAIL: email })
   }

   //!Remove this function if not use for ecommerce project
   const handleSubmit = async (email) => {
      const client = initializeApollo(process.env.GUEST_TOKEN)
      let { data, errors } = await client.mutate({ mutation: M_SUBSCRIBE, variables: { email } });
      if (data) {
         return { data };
      }
      return { errors }
   };

   return (
      <>
         {query ? (
            <Mailchimpform
               handleSubmit={handleSubmit}
               inputClassName={inputClassName}
               btnClassName={btnClassName}
               className={className}
               btnVariant={btnVariant}
               btnWrapperClassName={btnWrapperClassName}
               query
               fn={fn}
            />
         ) : (
            <MailchimpSubscribe
               url={process.env.MAILCHIMP_URL}
               render={({ ...props }) => (
                  <Mailchimpform
                     {...props}
                     inputClassName={inputClassName}
                     btnClassName={btnClassName}
                     className={className}
                     btnVariant={btnVariant}
                     btnWrapperClassName={btnWrapperClassName}
                     handleSubmit={(e, email) =>
                        handleSubscribe(e, email, props.subscribe)
                     }
                     fn={fn}
                  />
               )}
            />
         )}
      </>
   )
}

//Mailchimp form input
const Mailchimpform = ({
   subscribe,
   status,
   message,
   handleSubmit,
   btnClassName,
   btnVariant,
   inputClassName,
   className,
   btnWrapperClassName,
   fn,
   query
}) => {

   //States **************************************************************** //
   const [state, setState] = useState({
      email: "",
      loading: null,
      status: { text: null, status: null, className: "" },
   })
   //States **************************************************************** //

   //Functions ************************************************************* //
   const handleSubscribe = async (e, email) => {
      e.preventDefault();
      setState(prev => ({ ...prev, loading: true }))
      let { data, errors } = await handleSubmit(email);
      if (data) {
         let { status, message } = data?.subscribe;
         if (fn) fn({ data });
         let payload = { text: message, }
         if (status === "success") payload = { ...payload, status: "success" }
         else payload = { ...payload, status: "danger" }
         setState(prev => ({ ...prev, status: { ...payload }, loading: null, email: "" }))
      } else {
         setState(prev => ({ ...prev, loading: null }))
         if (fn) fn({ errors });
      }
   }
   //Functions ************************************************************* //

   //React operations ****************************************************** //
   React.useEffect(() => {
      if (status) {
         if (status === "sending") setState((prev) => ({ ...prev, loading: true }))
         else if (status === "success")
            setState((prev) => ({
               ...prev,
               status: { text: message, status: "success", className: "" },
               loading: false,
               email: null,
            }))
         else
            setState((prev) => ({
               ...prev,
               status: {
                  text: "Oops! something went wrong",
                  status: "danger",
                  className: "",
               },
            }))
         if (fn) fn({ data: { status, message } })
      }
   }, [status])
   //React operations ****************************************************** //


   return (
      <>
         <form onSubmit={(e) => {
            query ? handleSubscribe(e, state.email) : handleSubmit(e, state.email)
         }} className={`${className}`}>
            <Input
               value={state.email}
               placeholder="Enter your email address"
               type="email"
               className={`${inputClassName}`}
               disabled={state?.status?.status === "success" ? true : false}
               onChange={(e) =>
                  setState((prev) => ({ ...prev, email: e.target.value }))
               }
            />

            <div className={btnWrapperClassName}>
               <Button
                  className={
                     `${btnClassName}
                      ${state.loading && "loading"} btn-newsletter
                       ${state?.status?.status === "success" ? "submitted" : ""}`
                  }
                  variant={`${btnVariant || "primary"}`}
                  disabled={state?.status?.status === "success" ? true : false}
                  type="submit"
                  loader
               >
                  {state?.status?.status === "success" ? (
                     <i className="ail ai-check" />
                  ) : (
                     "SUBMIT"
                  )}
               </Button>
            </div>
            {/* ERROR/SUCCESS STATE ******************* */}
            {/* {state?.status?.text ? (
               <div className={state.status.className}>{state?.status?.text}</div>
            ) : null} */}
            {/* ERROR/SUCCESS STATE ******************* */}
         </form>
      </>
   )
}

NewsLetter.PropTypes = {
   fn: PropTypes.func,
}

// Newsletter.propTypes = {
//   btnName: PropTypes.string,
//   btnClassName: PropTypes.string,
//   btnVariant: PropTypes.string,
//   className: PropTypes.string,
//   placeholder: PropTypes.string,
//   existMsg: PropTypes.string,
//   successMsg: PropTypes.string,
//   failedMsg: PropTypes.string,
//   floatingLabel: PropTypes.bool,
//   variant: PropTypes.oneOf(["basic", "underline"]),
//   theme: PropTypes.oneOf(["light", "dark"]),
//   label: PropTypes.string,
//   inputClassName: PropTypes.string,
// }

// <MailchimpSubscribe
//    url={process.env.MAILCHIMP_URL}
//    render={({ subscribe, status, message }) => {
//       handleMailState(status)
//       return (
//          <>
//             <div className="newsletter">
//                <Input
//                   value={email}
//                   placeholder="Enter your email address"
//                   onChange={(e) => setEmail(e.target.value)}
//                />
//                {/* <div onClick={newsletterDataLayer}> */}
//                <Button
//                   className={`bg-white text-black border-0 ${loading && "loading"}`}
//                   disabled={!isEmail || status == "success" || disabled}
//                   loader
//                   onClick={(e) => {
//                      handleSubmit(e, {
//                         onValidated: (email) => subscribe(email),
//                      })
//                   }}
//                >
//                   {status === "success" ? (
//                      <i className="ail ai-check" />
//                   ) : (
//                      "SUBMIT"
//                   )}
//                </Button>
//                {/* </div> */}
//             </div>
//             {status == "success" && <small>{message}</small>}
//             {status == "error" &&
//                message?.includes("already subscribed") ? (
//                <small>Email already subscribed</small>
//             ) : (
//                <>
//                   {/* message && <small>Oops! something went wrong</small> */}
//                </>
//             )}
//          </>
//       )
//    }}
// />

// import React, { useState } from "react"
// import PropTypes from "prop-types"

// import addToMailchimp from "gatsby-plugin-mailchimp"
// import { Formik } from "formik"

// import { Input } from "components/anti/forms/forms"
// import { Button } from "components/anti/buttons/buttons"

// export const Newsletter = ({
//   btnName,
//   btnClassName,
//   btnVariant,
//   className,
//   placeholder,
//   existMsg,
//   successMsg,
//   failedMsg,
//   floatingLabel,
//   variant,
//   theme,
//   label,
//   inputClassName,
// }) => {
//   const [error, setError] = useState("")
//   const [status, setStatus] = useState(false)
//   const [email, setEmail] = useState("")
//   const handleChange = e => {
//     setEmail(e.target.value)
//   }
//   const handleSubmit = async e => {
//     e.preventDefault()
//     setStatus(true)
//     const result = await addToMailchimp(email)
//     if (result.msg == "Thank you for subscribing!") {
//       setError(successMsg)
//       setStatus(false)
//     } else if (result.msg == "The email you entered is not valid.") {
//       setError(failedMsg)
//       setStatus(false)
//     } else {
//       setError(existMsg)
//       setStatus(false)
//     }
//   }
//   return (
//     <div className={`newsletter ${className}`}>
//       <Formik>
//         <>
//           <div className="input-group">
//             <Input
//               formik
//               floatingLabel={floatingLabel}
//               variant={variant}
//               theme={theme}
//               label={label}
//               placeholder={placeholder}
//               inputClassName={inputClassName}
//               as="input"
//               type="email"
//               id="email"
//               name="email"
//               onChange={handleChange}
//               errors={error}
//             />
//             <div className="input-group-append">
//               <Button
//                 variant={btnVariant}
//                 className={`newsletter-submit ${btnClassName} ${status &&
//                   "loading"}`}
//                 loader={status && true}
//                 onClick={handleSubmit}
//               >
//                 {btnName}
//               </Button>
//             </div>
//           </div>
//           <small>{error}</small>
//         </>
//       </Formik>
//     </div>
//   )
// }

// Newsletter.propTypes = {
//   btnName: PropTypes.string,
//   btnClassName: PropTypes.string,
//   btnVariant: PropTypes.string,
//   className: PropTypes.string,
//   placeholder: PropTypes.string,
//   existMsg: PropTypes.string,
//   successMsg: PropTypes.string,
//   failedMsg: PropTypes.string,
//   floatingLabel: PropTypes.bool,
//   variant: PropTypes.oneOf(["basic", "underline"]),
//   theme: PropTypes.oneOf(["light", "dark"]),
//   label: PropTypes.string,
//   inputClassName: PropTypes.string,
// }

// Newsletter.defaultProps = {
//   btnName: "Send",
//   btnClassName: "",
//   btnVariant: "primary",
//   className: "",
//   placeholder: "Enter Email",
//   successMsg: "Thank you for subscribing!",
//   failedMsg: "The email you entered is not valid.",
//   existMsg: "Email is already registered.",
//   floatingLabel: false,
//   variant: "basic",
//   theme: "light",
//   label: null,
// }
