import React, { useState } from "react"
import { useRouter } from "next/router"

import { Input } from "components/anti"

const SearchMenu = ({ handleSearch }) => {
  const router = useRouter()

  const [searchKey, setSearchKey] = useState("")

  const navigateSearch = (e) => {
    if (e.key === "Enter") {
      router.push(`/search?key=${searchKey}`)
      handleSearch()
      e.target.blur()
    }
  }

  return (
    <div className="search-menu-desktop hide">
      <div className="container d-block mw-lg">
        <Input
          className={`w-100 search-input ${searchKey ? "active" : ""}`}
          id="search"
          name="search"
          placeholder="Type to search"
          iconLeft
          value={searchKey}
          // iconRight
          onChange={(e) => setSearchKey(e.target.value)}
          onKeyPress={navigateSearch}
        >
          <div
            className="btn-clear-search"
            onClick={() => (searchKey ? setSearchKey("") : {})}
          >
            {/* <div className="btn-icon"> */}
            <span className="air ai-times"></span>
            {/* <span className="air ai-search"></span> */}
            {/* </div> */}
          </div>
        </Input>
      </div>
    </div>
  )
}

export default SearchMenu
