import { Button, Image } from "components/anti"
import NotLoginModal from "components/pages/checkout/not-login-modal"
import React, { useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { FormatCurrency } from "lib/utils"
import { M_DELETE_CART_ITEM, Q_MY_CART, M_UPDATE_QTY_CART } from "lib/graphql"
import { useMutation, useLazyQuery } from "@apollo/client"
import { SET_CARTS } from "lib/redux/types"
import { useRouter } from "next/router"
import { parseCookies } from "nookies"
import { useClickOutside } from "components/hooks/hooks"

import labelHolders from "assets/img/karafuru/common/img-label-holders-only.png"
import { variantMapper2 } from "components/utils/helpers"
import { toastHandling } from "lib/utils/toast-handling"
import { dataLayerEvent } from "lib/datalayer/datalayer"

const BagMenu = ({ variant, handleShoppingBag, shoppingBagMenu }) => {
  const dispatch = useDispatch()
  const ref = useRef()
  const Router = useRouter()
  const cookies = parseCookies()
  const currency = cookies?.currency

  // States
  const [modal, setModal] = useState(null)
  const [refreshCart, setRefreshCart] = useState(false)
  const data = useSelector((state) => state.cart?.cartList)
  const { carts } = useSelector((state) => state.cart)
  const user = useSelector((state) => state.auth?.user_data)
  let subtototal = 0
  data.map((item) => {
    subtototal += item.price
  })

  // Apollo
  const [deleteCart] = useMutation(M_DELETE_CART_ITEM, { errorPolicy: "all" })
  const [getCarts, cartsResult] = useLazyQuery(Q_MY_CART, {
    errorPolicy: "all",
    variables: {
      currency,
    },
  })

  // Function
  const handleDeleteCart = async (id) => {
    let { data, errors } = await deleteCart({ variables: { id } })
    if (data) {
      setRefreshCart(true)
      if (data.deleteCart?.message === "Success delete item") {
        if (carts.items.length > 1) {
          getCarts()
        } else {
          dispatch({ type: SET_CARTS, payload: null })
        }
      }
      setTimeout(() => {
        setRefreshCart(false)
      }, 500)
    } else {
      //!NEED ERROR HANDLING
      toastHandling({ type: "general" })
    }
  }
  const handleNavigateCheckout = (user) => {
    handleShoppingBag()
    // dataLayer.push({
    //   event: "checkout",
    //   eventCategory: "Product", //Product
    //   eventAction: "Product Checkout", //Product Checkout
    //   eventLabel: "Checkout Step 1 - Shopping Bag", //Checkout Step 1 - Shopping Bag
    //   ecommerce: {
    //     checkout: {
    //       actionField: {
    //         step: 1,
    //         option: "cart page checkouted",
    //         action: "checkout",
    //       },
    //       currencyCode: currency, //IDR, USD, etc
    //       products: [
    //         ...carts.items.map((cart) => ({
    //           id: cart.product_id, //SKU001
    //           name: cart.title, //Gazzele Hi Matcha White
    //           price: cart.sell_price, //388000
    //           quantity: cart.quantity, // 1
    //           // "category": cart.title, // Gazzele
    //           brand: "Karafuru", //Compass
    //           variant: variantMapper2(cart?.option_1, cart?.option_2), //Size 35, Size 41, etc.
    //         })),
    //       ],
    //     },
    //   },
    //   userId: user ? user.id : 0, // 0 if not login
    // })
    dataLayerEvent.beginCheckout({ ...carts, currency })
    if (user) {
      setTimeout(() => {
        window.location.href = "/checkout"
        // router.push("/checkout");
      }, 1000)
    } else {
      setTimeout(() => {
        window.location.href = "/checkout/auth"
      }, 1000)
    }
  }

  // React Operations
  React.useEffect(() => {
    let { data, error } = cartsResult
    if (data) {
      if (data?.carts) {
        if (data?.carts.items.length > 0) {
          dispatch({ type: SET_CARTS, payload: data.carts })
        }
      }
    } else {
      //!NEED ERROR HANDLING
    }
  }, [cartsResult.data])

  useClickOutside(ref, () => {
    if (shoppingBagMenu.clicked) {
      handleShoppingBag()
    }
  })

  useEffect(() => {
    if (shoppingBagMenu.clicked) {
      dataLayerEvent.viewCart({ ...carts, currency })
    }
  }, [shoppingBagMenu])

  return (
    <div className={`bag-menu mobile-menu ${variant}`} ref={ref}>
      <NotLoginModal
        modal={modal}
        setModal={setModal}
        handleShoppingBag={handleShoppingBag}
      />
      <div className="bag-menu-content">
        <div className="close-button" onClick={handleShoppingBag}>
          <i className="ail ai-times" />
        </div>

        <div className="heading">
          <div className="container">
            <h2 className=" title-shopping-bag">
              Shopping Bag{" "}
              {/* <small className="text-primary">
                {carts ? carts?.items?.length : 0}
              </small>{" "} */}
            </h2>
          </div>
        </div>

        <div className="bag-menu-content-top">
          <div
            className={`container ${!carts?.items?.length ? "is-empty" : ""}`}
          >
            {refreshCart ? (
              <div className="common-loader w-100">
                <i className="ail ai-spinner-third" />
              </div>
            ) : (
              <>
                {carts?.items?.length ? (
                  carts?.items?.map((item, i) => (
                    <CartItem
                      id={carts.id}
                      item={item}
                      key={i}
                      handleDeleteCart={handleDeleteCart}
                      currency={currency}
                    />
                  ))
                ) : (
                  <div className="empty-state">
                    <h5>There are no items in your bag</h5>
                    <Button link="/product" onClick={() => handleShoppingBag()}>
                      Browse All Product
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="bag-menu-content-bottom py-3">
          <div className="container">
            <div className="content-top">
              <div className="content-top-left">
                <p>Subtotal</p>
                <small>Have coupon code? Use it on check out.</small>
              </div>
              <div className="content-top-right">
                <p>
                  {currency} {carts ? FormatCurrency(carts.subtotal) : "0"}
                </p>
              </div>
            </div>
            <Button
              variant="primary"
              className="w-100"
              disabled={!carts}
              onClick={() => handleNavigateCheckout(user)}
            >
              Proceed to Checkout
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

const CartItem = ({ item, handleDeleteCart, id, currency }) => {
  //Utils ************************************************************************************** //
  const dispatch = useDispatch()
  //Utils ************************************************************************************** //

  //States ************************************************************************************* //
  const [state, setState] = React.useState({
    qty: item.quantity,
    plusBtn: item.quantity === item.stock ? true : false,
    minusBtn: item.quantity == 1 ? true : false,
    loading: false,
  })
  //States ************************************************************************************* //

  //Apollo ************************************************************************************* //
  const [updateCart] = useMutation(M_UPDATE_QTY_CART, { errorPolicy: "all" })
  //Apollo ************************************************************************************* //

  //Functions ********************************************************************************** //
  const handlePress = async (type, id, value) => {
    let qty
    let manInput
    if (value) {
      qty === value
    } else {
      if (type === "increment") {
        qty = state.qty + 1
        // setState(prev => ({ ...prev, plusBtn: true }))
      } else if (type === "decrement") {
        if (state.qty === 1) qty = 1
        else qty = state.qty - 1
      } else {
        manInput = parseInt(type)
        qty = manInput < 0 ? 1 : manInput
      }
    }

    try {
      // setState(prev => ({ ...prev, loading: true }))
      let { data, errors } = await updateCart({
        variables: { id: item.id, quantity: qty, currency },
      })
      if (data && data?.updateCart) {
        let { message } = data?.updateCart
        if (message === "The stock is not sufficient") {
          setState((prev) => ({ ...prev, plusBtn: true }))
        } else {
          if (type === "increment") {
            let { quantity, stock } = data?.updateCart?.items.find(
              (el) => el.id === id
            )

            if (quantity === stock) {
              setState((prev) => ({
                ...prev,
                qty,
                plusBtn: true,
                minusBtn: false,
              }))
            } else {
              setState((prev) => ({
                ...prev,
                qty,
                plusBtn: false,
                minusBtn: false,
              }))
            }
          } else if (type === "decrement") {
            setState((prev) => ({ ...prev, plusBtn: false, qty }))
          } else {
            setState((prev) => ({
              ...prev,
              qty,
            }))
          }

          if (qty === 1) setState((prev) => ({ ...prev, minusBtn: true }))
          else setState((prev) => ({ ...prev, minusBtn: false }))

          dispatch({ type: SET_CARTS, payload: data?.updateCart })
        }
      }
    } catch (error) {
      toastHandling({ type: "general" })
    }
  }

  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      qty: item.quantity,
      plusBtn: item.quantity === item.stock ? true : false,
      minusBtn: item.quantity == 1 ? true : false,
      loading: false,
    }))
  }, [item])

  return (
    <div className="product-item">
      <div className="col-image">
        <Image src={item.image} ratio="r-10-13" className="img-fluid" />
        {item.for_holder === 1 && (
          <img
            src={labelHolders}
            className="img-fluid label-holders-only others"
          />
        )}
      </div>
      <div className="col-content">
        <p className="name">{item.title}</p>
        <div className="att">
          <div className="att-wrapper">
            {item?.option_1 && (
              <p className="att-item size">
                {item?.option_1?.title}:{" "}
                <strong className="ml-1">{item?.option_1?.value}</strong>
              </p>
            )}
            {item?.option_2 && (
              <p className="att-item size">
                {item?.option_2?.title}:{" "}
                <strong className="ml-1">{item?.option_2?.value}</strong>
              </p>
            )}
          </div>
          <div className="att-item qty-adjust">
            <Button
              variant="primary"
              disabled={state.minusBtn}
              className="btn-minus"
              onClick={() => handlePress("decrement")}
            />
            <input
              type="number"
              className="form-control"
              value={state.qty}
              defaultValue={state.qty}
              onChange={(e) => handlePress(e.target.value)}
            />
            <Button
              variant="primary"
              disabled={state.loading || state.plusBtn ? true : false}
              className="btn-plus"
              onClick={() => handlePress("increment", item.id)}
            />
          </div>
        </div>
        <p className="price">
          {currency} {FormatCurrency(item.sell_price)}
        </p>
        <div className="btn-remove" onClick={() => handleDeleteCart(item.id)}>
          <i className="air ai-trash" />
        </div>
      </div>
    </div>
  )
}

export default BagMenu
