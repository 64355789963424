import React, { useEffect, useLayoutEffect, useState } from "react"
import { Link } from "components/anti/link/link"
import { truncate } from "components/utils/helpers"
import MobileShopMenu from "./mobile-shop-menu"
import MobileAccountMenu from "./mobile-account-menu"
import { Button } from "components/anti/buttons/buttons"
import { Select } from "components/anti/forms/forms"
import ConnectWalletModal from "components/global/connect-wallet-modal/connect-wallet-modal"
import { SET_CURRENCY } from "lib/redux/types"
import { useDispatch, useSelector } from "react-redux"
import useConnectWallet from "components/hooks/useConnectWallet"
import { useRouter } from "next/router"
import { setCookie } from "nookies"
import { toastHandling } from "lib/utils/toast-handling"

const listCurrency = [
  { label: "IDR", value: 0 },
  { label: "USD", value: 1 },
]

const MobileMenu = ({
  navExpand,
  variant,
  walletAddress,
  handleShopMenuMobile,
  disabledShopMenu,
  handleMenuMobile,
  handleAccountMenuMobile,
  disabledAccountMenu,
}) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const { account, onActivate, error } = useConnectWallet()
  const { user_data } = useSelector((state) => state.auth)
  const { currency } = useSelector((state) => state.general)
  const [modal, setModal] = useState(null)
  const [activeState, setActiveState] = useState("")
  const [activeSubState, setActiveSubState] = useState("")
  const [loading, setLoading] = useState(null)

  useLayoutEffect(() => {
    const pathname = window.location.pathname.split("/")
    setActiveState(pathname[1])
    setActiveSubState(window.location.search)
  }, [])

  useLayoutEffect(() => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }, [])

  const onConnectWallet = async (connector) => {
    setLoading(connector)
    try {
      await onActivate(connector)
      setModal(null)
    } catch (err) {
      return toastHandling({ message: err.message, status: "danger" })
    } finally {
      setLoading(null)
    }
  }

  useEffect(() => {
    if (account && loading) {
      setLoading(null)
      toastHandling({
        message: "You have successfully connected your wallet",
        status: "success",
      })
    }
  }, [account, loading])

  useEffect(() => {
    if (error) {
      setLoading(null)
    }
  }, [error])

  const onChangeCurrency = (value) => {
    dispatch({ type: SET_CURRENCY, payload: value })
    setCookie(null, "currency", value.label, {
      maxAge: 30 * 24 * 60 * 60,
      path: "/",
    })
    router.reload()
  }

  const handleRedirect = (link) => {
    router.push(link)
    handleMenuMobile()
  }

  return (
    <>
      <ConnectWalletModal
        modal={modal}
        setModal={setModal}
        onClick={onConnectWallet}
        loading={loading}
        isShopDetail={false}
      />
      <div
        className={`mobile-menu mobile-menu-main d-${navExpand}-none ${variant}`}
      >
        <div className="mobile-menu-content">
          <ul className={`navbar-nav ${!user_data && "show-login"}`}>
            <li className="nav-item">
              <Link
                className={`nav-link anim-1 ${activeState === "" && "active"}`}
                activeClassName="active"
                onClick={() => handleRedirect("/")}
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link anim-2 ${
                  activeState == "products" && "active"
                }`}
                activeClassName="active"
                onClick={handleShopMenuMobile}
              >
                Shop
              </Link>
            </li>
            {user_data && (
              <li className="nav-item">
                <Link
                  className={`nav-link anim-3`}
                  activeClassName="active"
                  onClick={handleAccountMenuMobile}
                >
                  My Account
                </Link>
              </li>
            )}
          </ul>
          {!user_data && (
            <div className="login-footer">
              <div className="mb-3">
                <span className="text-white">
                  Become a member for daily Karafuru inspiration in your life
                </span>
              </div>
              <Button
                variant="primary"
                className={`border-0`}
                onClick={(e) => {
                  router.push("/auth")
                }}
              >
                LOGIN/REGISTER
              </Button>
            </div>
          )}
        </div>
        <div className="mobile-menu-footer">
          <Select
            label=""
            id="currency"
            options={listCurrency}
            value={currency}
            onChange={onChangeCurrency}
            className="select-currency"
          />
          {account ? (
            <Link className="nav-link nav-link-wallet px-3 py-1">
              {truncate(account, 13)}
            </Link>
          ) : (
            <Button
              variant="dark"
              className="py-1"
              onClick={() => setModal("connect-wallet-modal")}
            >
              CONNECT WALLET
            </Button>
          )}
        </div>
      </div>

      <MobileShopMenu
        navExpand={navExpand}
        variant={variant}
        handleShopMenuMobile={handleShopMenuMobile}
        disabledShopMenu={disabledShopMenu}
        handleMenuMobile={handleMenuMobile}
      />
      <MobileAccountMenu
        navExpand={navExpand}
        variant={variant}
        handleAccountMenuMobile={handleAccountMenuMobile}
        disabledAccountMenu={disabledAccountMenu}
        handleMenuMobile={handleMenuMobile}
      />
      <div className="second-menu menu-bg" />
    </>
  )
}

export default MobileMenu
