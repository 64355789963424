import { variantMapper2 } from "components/utils/helpers"
import { useSelector } from "react-redux"

export const buttonClickDatalayer = (path, text) => {
  dataLayer.push({
    event: "interaction",
    eventCategory: "Button Click",
    eventAction: path, // /shop, /product
    eventLabel: text, // Browse Products
  })
}

export const productClickDatalayer = (name, product, i) => {
  const { user_data } = useSelector((state) => state.auth)

  dataLayer.push({
    event: "productClick",
    eventCategory: "Product", //Product
    eventAction: "Product Click", // Product Click
    eventLabel: `${name} - ${product.title}`, // Timeless Choice - Retrograde Hi White Red, etc.
    ecommerce: {
      click: {
        actionField: {
          list: name, // Timeless Choice,etc.
          action: "click",
        },
        products: [
          {
            name: product.title, //Retrograde Hi White Red
            id: product.id, //SKU001 *must same with the product View ID
            price: product.price, //568000.00
            brand: "Karafuru", //Compass
            category: product.firstcategory.title, //Retrograde, Gazelle, Darahku Biru
            list: name, //Timeless Choice, etc.
            position: i + 1, //1 - Timeless Choice.
          },
        ],
      },
    },
    userId: user_data ? user_data.id : 0, //0 if not login
  })
}

const sendDataLayer = (sendData) => {
  dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
  dataLayer.push(sendData)
}

export const dataLayerEvent = {
  selectItem: (data, index) => {
    const sendData = {
      event: "select_item",
      ecommerce: {
        item_list_id: data?.listId,
        item_list_name: data?.listName,
        items: [
          {
            index,
            quantity: 1,
            item_id: data?.id,
            price: data?.price,
            item_brand: "Karafuru",
            item_name: data?.title,
            item_list_id: data?.listId,
            item_list_name: data?.listName,
            item_category: data?.firstcategory?.title,
          },
        ],
      },
    }
    sendDataLayer(sendData)
  },
  addToCart: (data) => {
    const sendData = {
      event: "add_to_cart",
      ecommerce: {
        currency: data?.currency, //IDR, USD
        value: data?.subTotal,
        items: [
          {
            index: 0,
            quantity: 1,
            item_id: data.sku_id,
            item_name: data?.title,
            item_list_id: data?.listId,
            item_list_name: data?.listName,
            item_brand: "Karafuru",
            variant: data?.variant,
            price: data?.sell_price,
          },
        ],
      },
    }
    sendDataLayer(sendData)
  },
  viewCart: (data) => {
    const sendData = {
      event: "view_cart",
      value: data?.grand_total,
      ecommerce: {
        currency: data?.currency, //IDR, USD
        items: data?.items.map((x, i) => ({
          item_id: x?.sku_id, //SKU001,
          item_name: x?.title,
          index: i,
          item_brand: "Karafuru",
          // item_category: {{product category}}, //Clothing, accessories, other,
          // item_list_id: {{list_id}},
          // item_list_name: {{list name}}, //new arrival, best seller, category page, search result, related product,
          price: x?.sell_price,
          quantity: x.quantity,
          variant: variantMapper2(x.option_1, x.option_2),
        })),
      },
    }
    sendDataLayer(sendData)
  },
  viewItem: (data) => {
    const sendData = {
      event: "view_item",
      ecommerce: {
        currency: data?.currency, //IDR, USD
        value: data?.sell_price,
        items: [
          {
            item_id: data?.id, //SKU001,
            item_name: data?.title,
            index: 0,
            item_brand: "Karafuru",
            item_list_id: data?.listId,
            item_list_name: data?.listName,
            item_category: data?.firstcategory?.title,
            price: data?.sell_price,
            quantity: 1,
          },
        ],
      },
    }
    sendDataLayer(sendData)
  },
  viewItemList: (data) => {
    const sendData = {
      event: "view_item_list",
      ecommerce: {
        item_list_id: data?.listId,
        item_list_name: data?.listName,
        items: [
          {
            item_id: data?.id, //SKU001,
            item_name: data?.title,
            index: data?.position,
            item_brand: "Karafuru",
            item_list_id: data?.listId,
            item_list_name: data?.listName,
            item_category: data?.firstcategory?.title,
            price: data?.sell_price,
            quantity: 1,
          },
        ],
      },
    }
    sendDataLayer(sendData)
  },
  beginCheckout: (data) => {
    const sendData = {
      event: "begin_checkout",
      ecommerce: {
        currency: data?.currency,
        value: data?.grand_total,
        items: data?.items.map((x, i) => ({
          item_id: x?.sku_id, //SKU001,
          item_name: x?.title,
          index: i,
          item_brand: "Karafuru",
          // item_category: {{product category}}, //Clothing, accessories, other,
          // item_list_id: {{list_id}},
          // item_list_name: {{list name}}, //new arrival, best seller, category page, search result, related product,
          price: x?.sell_price,
          quantity: x.quantity,
          variant: variantMapper2(x.option_1, x.option_2),
        })),
      },
    }
    sendDataLayer(sendData)
  },
  purchase: (data) => {
    const sendData = {
      event: "purchase",
      ecommerce: {
        transaction_id: data?.id,
        value: data?.total_amount,
        shipping: data?.shipping_fee,
        currency: data?.currency,
        coupon: data?.coupon,
        items: data?.details?.map((x, i) => ({
          item_id: x?.sku_id, //SKU001,
          item_name: x?.title,
          index: i,
          item_brand: "Karafuru",
          // item_category: {{product category}}, //Clothing, accessories, other,
          // item_list_id: {{list_id}},
          // item_list_name: {{list name}}, //new arrival, best seller, category page, search result, related product,
          price: x?.sell_price,
          quantity: x.quantity,
          variant: variantMapper2(x.option_1, x.option_2),
        })),
      },
    }
    sendDataLayer(sendData)
  },
  errorPayment: (data) => {
    dataLayer.push({
      event: "error_payment",
      error_message: data?.errorMessage,
    })
  },
}
