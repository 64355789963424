// ░█▀▀▀ ░█▀▀█ ░█▀▀▀ ░█▀▀▀ 　 ▀▀█▀▀ ░█▀▀▀█ 　 ░█▀▀▀ ░█▀▀▄ ▀█▀ ▀▀█▀▀
// ░█▀▀▀ ░█▄▄▀ ░█▀▀▀ ░█▀▀▀ 　 ─░█── ░█──░█ 　 ░█▀▀▀ ░█─░█ ░█─ ─░█──
// ░█─── ░█─░█ ░█▄▄▄ ░█▄▄▄ 　 ─░█── ░█▄▄▄█ 　 ░█▄▄▄ ░█▄▄▀ ▄█▄ ─░█──
// The component is made to be edited. However, please edit the styling on .footer-custom.scss.

// ========================================================================================================================================
// Footer
// ========================================================================================================================================

import React, { useEffect, useState } from "react"
import MailchimpSubscribe from "react-mailchimp-subscribe"

import { Link } from "components/anti"
import { Input } from "../forms/forms"
import { Button } from "components/anti/buttons/buttons"

import arrow from "assets/img/common/arrow-ne-white.svg"

// import payment1 from "assets/img/common/logo_bca_2.svg"
// import payment2 from "assets/img/common/logo_bca_3.svg"
import payment3 from "assets/img/common/logo_bca_4.png"
// import payment4 from "assets/img/common/logo_bca_5.svg"
// import payment5 from "assets/img/common/logo_bca_6.svg"
import payment6 from "assets/img/common/mastercard.png"
import logoAll from "assets/img/karafuru/logo/logo_all-white.png"
import { capitalize } from "lodash"
import twitter from "assets/img/karafuru/icon/ic-twitter.png"
import discord from "assets/img/karafuru/icon/ic-discord.png"
import youtube from "assets/img/karafuru/icon/ic-youtube.png"
import opensea from "assets/img/karafuru/icon/ic-opensea.png"
import instagram from "assets/img/karafuru/icon/ic-instagram.png"
import website from "assets/img/karafuru/icon/ic-website.png"

import { NewsLetter } from 'components/anti';

import { useRouter } from "next/router"
import { Social } from "../social/social"
import { toastHandling } from "lib/utils/toast-handling"

export const Footer = ({ showFooter }) => {
  const router = useRouter()
  const menuLinks = [
    { title: "FAQ", link: "/faq" },
    { title: "Contact Us", link: "/contact-us" },
    { title: "Karafuru.io", link: "https://karafuru.io" },
    { title: "Terms & Conditions", link: "/terms-and-condition" },
    { title: "Privacy Policy", link: "/privacy-policy" },
  ]

  // const contactLinks = [
  //   { title: "+62 811 170 165", link: "phone:+62811170165" },
  //   { title: "info@antikode.com", link: "mailto:info@antikode.com" },
  // ]

  const paymentList = [
    payment3,
    payment6,
    // payment5,
    // payment2,
    // payment4,
  ]

  const socialList = [
    {
      icon: twitter,
      name: "twitter",
      link: "https://twitter.com/karafuruNFT",
    },
    {
      icon: instagram,
      name: "instagram",
      link: "https://instagram.com/karafuruNFT",
    },
    {
      icon: discord,
      name: "discord",
      link: "https://discord.com/invite/karafuru",
    },
    {
      icon: opensea,
      name: "opensea",
      link: "https://opensea.io/collection/karafuru",
    },
  ]

  const socialListCompo = [
    {
      type: "twitter",
      url: "https://twitter.com/karafuruNFT",
    },
    {
      type: "instagram",
      url: "https://instagram.com/karafuruNFT",
    },
    {
      type: "discord",
      url: "https://discord.com/invite/karafuru",
    },
    {
      type: "opensea",
      url: "https://opensea.io/collection/karafuru",
    },
  ]

  const [email, setEmail] = useState("")
  const [isEmail, setIsEmail] = useState(false)
  const [loading, setLoading] = useState(true)
  const [disabled, setDisabled] = useState(false)
  const regxVal =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  useEffect(() => {
    if (email && regxVal.test(email)) {
      setIsEmail(true)
    } else {
      setIsEmail(false)
    }
  }, [email])

  const handleMailState = (status) => {
    if (status === "success") {
      setLoading(false)
      setEmail("")
    } else if (status === "sending") {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }

  // DataLayer Function

  const newsletterDataLayer = () => {
    dataLayer.push({
      event: "interaction",
      eventCategory: "Button Click",
      eventAction: router.pathname, // /shop, /product
      eventLabel: "Newsletter", //Newsletter
    })
  }

  const socialDataLayer = (name) => {
    dataLayer.push({
      event: "interaction",
      eventCategory: "Button Click",
      eventAction: router.pathname, // /shop, /product
      eventLabel: `Footer - ${name}`, //Footer - YouTube, etc.
    })
  }

  const othersLinkDataLayer = (title) => {
    dataLayer.push({
      event: "interaction",
      eventCategory: "Button Click",
      eventAction: router.pathname, // /shop, /product
      eventLabel: `Footer - ${title}`, //Footer - YouTube, etc.
    })
  }

  const date = new Date()

  const handleSubmit = (props) => {
    const { status } = props?.data?.subscribe
    toastHandling({
      status: status === "error" ? "danger" : status,
      message: status === "error" ?
        "Your email has been subscribed to our newsletter, please try another one" :
        "Thank you for subscribing to our newsletter!"
    })
  }

  return (
    <footer className={`${showFooter ? "show" : "hide"}`}>
      <section className="footer-header">
        <div className="container">
          <p>
            Sign up for the latest updates on new arrivals, exclusive access,
            and more.
          </p>
          <NewsLetter
            btnVariant="secondary"
            className="input-group input-group-newsletter"
            btnWrapperClassName="input-group-append"
            query
            fn={handleSubmit}
          />
        </div>
      </section>
      <section className="footer-main">
        <div className="container">
          <div className="footer-main-top">
            <div className="col-payment">
              <span>Supported Payment</span>
              {paymentList.map((item, i) => {
                return (
                  <img key={i} src={item} className="img-fluid" alt="Payment" />
                )
              })}
            </div>
            <div className="col-social">
              <span>Follow Us</span>
              <div className="social-list">
                {socialList.map((item, i) => {
                  return (
                    <Link
                      key={i}
                      to={item.link}
                      onClick={() => socialDataLayer(item.name)}
                    >
                      <img src={item.icon} className="img-fluid social-item" />
                    </Link>
                  )
                })}
                {/* <Social
                  data={socialListCompo}
                  variant="primary"
                  shape="circle"
                /> */}
              </div>
            </div>
          </div>
          <div className="footer-main-bottom">
            <div className="footer-main-bottom__list">
              {menuLinks.map((item, i) => {
                return (
                  <Link key={i} to={item.link} className="menu-link" target="">
                    {item.title}
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
      </section>

      {/* Footer Bottom */}
      <section className="footer-bottom">
        <div className="container">
          <span>© 2022 Karafuru. All rights reserved.</span>
          <img src={logoAll} className="img-fluid" alt="Karafuru" />
        </div>
      </section>
    </footer>
  )
}
