import React, { useState, useEffect } from "react"
import { parseCookies, setCookie } from "nookies"

import { Link } from "components/anti"
import DesktopMenu from "./components/desktop-menu"
import MobileMenu from "./components/mobile-menu"
import SearchMenuMobile from "./components/search-menu-mobile"
import BagMenu from "./components/bag-menu"
import SearchMenu from "./components/search-menu"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "../buttons/buttons"
import { SET_IS_DRAWER, SET_TOAST_HEIGHT, SET_TOAST } from "lib/redux/types"

import logoLight from "assets/img/karafuru/logo/logo-2.png"
import bgBlue from "assets/img/karafuru/bg/bg-nav-header-blue.png"
import bgPink from "assets/img/karafuru/bg/bg-nav-header.png"


export const Navbar = ({ withBg, withoutLink, showMenu, navColorProps }) => {
  // Navbar expand config
  // Change the const below & config on variables/navbar.scss when setting the .navbar-expand-*
  // e.g. sm, md, lg, xl, xxl
  const navExpand = "lg"

  // Default theme
  // Options: "navbar-dark", "navbar-light"
  const navTheme = "navbar-dark"

  // Default position
  // Options: "fixed-top", "absolute-top", ""
  const navPosition = "fixed-top"

  // IF NO NAVBAR EXPAND USED (no responsive navbar), do the following:
  // 1. In variables/navbar.scss, set $navbar-top-height-mobile & $navbar-top-height-desktop to 0
  // 2. In variables/navbar.scss, set $navbar-main-height-mobile & $nabvar-main-height-desktop to the same height
  // 3. In variables/navbar.scss, set $navbar-expand-up to ""
  // 4. In navbar.js, edit const navbarExpand to ""

  // SCROLL
  // On scroll state
  const [navScroll, setNavScroll] = useState(false)
  const dispatch = useDispatch()

  //!CARTS DATA
  const { carts } = useSelector((state) => state.cart)

  const useIsomorphicLayoutEffect =
    typeof window !== "undefined" ? React.useLayoutEffect : React.useEffect

  useIsomorphicLayoutEffect(() => {
    // if (window.pageYOffset > 20) {
    //   setNavScroll(true)
    // }
  }, [])

  useEffect(() => {
    // window.onscroll = function () {
    //   const secSection = document.querySelector("main section:nth-child(2)")
    //   if (window.pageYOffset > secSection.offsetTop) {
    //     setNavScroll(true)
    //   } else {
    //     setNavScroll(false)
    //   }
    // }
  }, [])

  // MENU - MOBILE
  // Open / close menu state & handler
  const [mobileMenu, setMobileMenu] = useState({
    initial: false,
    clicked: null,
  })
  // Toggler disabled state & handler
  const [disabledMenu, setDisabledMenu] = useState(false)
  const disableMenu = () => {
    setDisabledMenu(!disabledMenu)
    setTimeout(() => {
      setDisabledMenu(false)
    }, 1500) // set this with your mobile menu animation duration
  }
  const handleMenuMobile = () => {
    disableMenu()
    if (mobileMenu.initial === false) {
      setMobileMenu({ initial: null, clicked: true })
    } else if (mobileMenu.clicked === true) {
      setMobileMenu({ clicked: !mobileMenu.clicked })
      setMobileShopMenu({ clicked: false })
      setMobileAccountMenu({ clicked: false })
    } else if (mobileMenu.clicked === false) {
      setMobileMenu({ clicked: !mobileMenu.clicked })
    }
  }

  useEffect(() => {
    const navToggler = document.querySelector(".nav-toggler")
    const navMobileMenu = document.querySelector(".mobile-menu")
    const menuBg = document.querySelector(".first-menu")
    const html = document.querySelector("html")
    const body = document.querySelector("body")
    html.classList.remove("no-scroll")
    // prettier-ignore
    if (mobileMenu.clicked === false) {
      // Toggle inactive
      navToggler.classList.add("inactive")
      navMobileMenu.classList.remove("show")
      navMobileMenu.classList.add("hide")
      body.classList.remove("no-scroll")
      html.classList.remove("no-scroll")
      setTimeout(() => {
        menuBg.classList.remove("show")
        menuBg.classList.add("hide")
      }, 1500);
    } else if (mobileMenu.clicked === true || (mobileMenu.clicked === true && mobileMenu.initial === null)) {
      // Toggle active
      navToggler.classList.remove("inactive")
      navMobileMenu.classList.add("show")
      menuBg.classList.add("show")
      body.classList.add("no-scroll")
      html.classList.add("no-scroll")
    }
  }, [mobileMenu])

  // MENU SHOP - MOBILE
  // Open / close menu state & handler
  const [mobileShopMenu, setMobileShopMenu] = useState({
    initial: false,
    clicked: null,
  })
  // Toggler disabled state & handler
  const [disabledShopMenu, setDisabledShopMenu] = useState(false)
  const disableShopMenu = () => {
    setDisabledShopMenu(!disabledShopMenu)
    setTimeout(() => {
      setDisabledShopMenu(false)
    }, 1500) // set this with your mobile menu animation duration
  }
  const handleShopMenuMobile = () => {
    disableShopMenu()
    if (mobileShopMenu.initial === false) {
      setMobileShopMenu({ initial: null, clicked: true })
    } else if (mobileShopMenu.clicked === true) {
      setMobileShopMenu({ clicked: !mobileShopMenu.clicked })
    } else if (mobileShopMenu.clicked === false) {
      setMobileShopMenu({ clicked: !mobileShopMenu.clicked })
    }
  }

  useEffect(() => {
    const navMobileShopMenu = document.querySelector(".mobile-shop-menu")
    const menuBg = document.querySelector(".second-menu")
    // prettier-ignore
    if (mobileShopMenu.clicked === false) {
      // Toggle inactive
      navMobileShopMenu.classList.remove("show")
      navMobileShopMenu.classList.add("hide")
      setTimeout(() => {
        menuBg.classList.remove("show")
        menuBg.classList.add("hide")
      }, 500);
    } else if (mobileShopMenu.clicked === true || (mobileShopMenu.clicked === true && mobileShopMenu.initial === null)) {
      // Toggle active
      menuBg.classList.add("show")
      navMobileShopMenu.classList.add("show")
    }
  }, [mobileShopMenu])

  // MENU ACCOUNT - MOBILE
  // Open / close menu state & handler
  const [mobileAccountMenu, setMobileAccountMenu] = useState({
    initial: false,
    clicked: null,
  })
  // Toggler disabled state & handler
  const [disabledAccountMenu, setDisabledAccountMenu] = useState(false)
  const disableAccountMenu = () => {
    setDisabledAccountMenu(!disabledAccountMenu)
    setTimeout(() => {
      setDisabledAccountMenu(false)
    }, 1500) // set this with your mobile menu animation duration
  }
  const handleAccountMenuMobile = () => {
    disableAccountMenu()
    if (mobileAccountMenu.initial === false) {
      setMobileAccountMenu({ initial: null, clicked: true })
    } else if (mobileAccountMenu.clicked === true) {
      setMobileAccountMenu({ clicked: !mobileAccountMenu.clicked })
    } else if (mobileAccountMenu.clicked === false) {
      setMobileAccountMenu({ clicked: !mobileAccountMenu.clicked })
    }
  }

  useEffect(() => {
    const navMobileAccountMenu = document.querySelector(".mobile-account-menu")
    const menuBg = document.querySelector(".second-menu")
    // prettier-ignore
    if (mobileAccountMenu.clicked === false) {
      // Toggle inactive
      navMobileAccountMenu.classList.remove("show")
      navMobileAccountMenu.classList.add("hide")
      setTimeout(() => {
        menuBg.classList.remove("show")
        menuBg.classList.add("hide")
      }, 500);
    } else if (mobileAccountMenu.clicked === true || (mobileAccountMenu.clicked === true && mobileAccountMenu.initial === null)) {
      // Toggle active
      menuBg.classList.add("show")
      navMobileAccountMenu.classList.add("show")
    }
  }, [mobileAccountMenu])

  // SHOPPING BAG MENU
  // Open / close menu state & handler

  const { shoppingBagMenu } = useSelector((state) => state.cart)

  // const [shoppingBagMenu, setShoppingBagMenu] = useState({
  //   initial: false,
  //   clicked: null,
  // })
  const handleShoppingBag = () => {
    disableMenu()
    if (shoppingBagMenu.initial === false) {
      dispatch({
        type: SET_IS_DRAWER,
        payload: { initial: null, clicked: true },
      })
      // setShoppingBagMenu({ initial: null, clicked: true });
    } else if (shoppingBagMenu.clicked === true) {
      dispatch({ type: SET_IS_DRAWER, payload: { clicked: false } })
      // setShoppingBagMenu({ clicked: !shoppingBagMenu.clicked })
    } else if (shoppingBagMenu.clicked === false) {
      dispatch({ type: SET_IS_DRAWER, payload: { clicked: true } })
      // setShoppingBagMenu({ clicked: !shoppingBagMenu.clicked })
    }
  }
  useEffect(() => {
    // const navToggler = document.querySelector(".nav-toggler")
    const navShoppingBagMenu = document.querySelector(".bag-menu")
    const menuBg = document.querySelector(".first-menu")
    const html = document.querySelector("html")
    const body = document.querySelector("body")
    // prettier-ignore
    if (shoppingBagMenu.clicked === false) {
      // Toggle inactive
      // navToggler.classList.add("inactive")
      navShoppingBagMenu.classList.remove("show")
      navShoppingBagMenu.classList.add("hide")
      menuBg.classList.remove("show")
      menuBg.classList.add("hide")
      body.classList.remove("no-scroll")
      html.classList.remove("no-scroll")
    } else if (shoppingBagMenu.clicked === true || (shoppingBagMenu.clicked === true && shoppingBagMenu.initial === null)) {
      // Toggle active
      // navToggler.classList.remove("inactive")
      navShoppingBagMenu.classList.add("show")
      menuBg.classList.add("show")
      body.classList.add("no-scroll")
      html.classList.add("no-scroll")
    }
  }, [shoppingBagMenu])

  // SEARCH
  // Open / close menu state & handler
  const [search, setSearch] = useState({
    initial: false,
    clicked: null,
  })
  const [pathname, setPathname] = useState("")
  // Toggler disabled state & handler
  const [disabledSearch, setDisabledSearch] = useState(false)
  const disableSearch = () => {
    setDisabledSearch(!disabledSearch)
    setTimeout(() => {
      setDisabledSearch(false)
    }, 1500) // set this with your mobile menu animation duration
  }
  const handleSearch = () => {
    disableSearch()
    if (search.initial === false) {
      setSearch({ initial: null, clicked: true })
    } else if (search.clicked === true) {
      setSearch({ clicked: !search.clicked })
    } else if (search.clicked === false) {
      setSearch({ clicked: !search.clicked })
    }
  }
  useEffect(() => {
    // const searchMenu = document.querySelector(".search-menu")
    const searchMenuDesktop = document.querySelector(".search-menu-desktop")
    // const html = document.querySelector("html")
    // const body = document.querySelector("body")
    // const navbarMain = document.querySelector(".navbar-main")
    // prettier-ignore
    if (search.clicked === false) {
      // Toggle inactive
      // searchMenu.classList.remove("show")
      // searchMenu.classList.add("hide")
      searchMenuDesktop.classList.remove("show")
      searchMenuDesktop.classList.add("hide")
      // body.classList.remove("no-scroll")
      // html.classList.remove("no-scroll")
      // navbarMain.classList.remove("search-show")
      // navbarMain.classList.add("search-hide")
    } else if (search.clicked === true || (search.clicked === true && search.Mobileinitial === null)) {
      // Toggle active
      // searchMenu.classList.add("show")
      searchMenuDesktop.classList.add("show")
      // body.classList.add("no-scroll")
      // html.classList.add("no-scroll")
      // navbarMain.classList.add("search-show")
    }
  }, [search])

  // SEARCH MOBILE
  // Open / close menu state & handler
  const [searchMobile, setSearchMobile] = useState({
    initial: false,
    clicked: null,
  })
  // Toggler disabled state & handler
  const [disabledSearchMobile, setDisabledSearchMobile] = useState(false)
  const disableSearchMobile = () => {
    setDisabledSearchMobile(!disabledSearchMobile)
    setTimeout(() => {
      setDisabledSearchMobile(false)
    }, 1500) // set this with your mobile menu animation duration
  }
  const handleSearchMobile = () => {
    disableSearchMobile()
    if (searchMobile.initial === false) {
      setSearchMobile({ initial: null, clicked: true })
    } else if (searchMobile.clicked === true) {
      setSearchMobile({ clicked: !searchMobile.clicked })
    } else if (searchMobile.clicked === false) {
      setSearchMobile({ clicked: !searchMobile.clicked })
    }
  }
  useEffect(() => {
    // const searchMenu = document.querySelector(".search-menu")
    const searchMenuCustomMobile = document.querySelector(".search-menu-mobile")
    const html = document.querySelector("html")
    const body = document.querySelector("body")
    // const navbarMain = document.querySelector(".navbar-main")
    // prettier-ignore
    if (searchMobile.clicked === false) {
      // Toggle inactive
      // searchMenu.classList.remove("show")
      // searchMenu.classList.add("hide")
      searchMenuCustomMobile.classList.remove("show")
      searchMenuCustomMobile.classList.add("hide")
      body.classList.remove("no-scroll")
      html.classList.remove("no-scroll")
      // navbarMain.classList.remove("search-show")
      // navbarMain.classList.add("search-hide")
    } else if (searchMobile.clicked === true || (searchMobile.clicked === true && search.Mobileinitial === null)) {
      // Toggle active
      // searchMenu.classList.add("show")
      searchMenuCustomMobile.classList.add("show")
      body.classList.add("no-scroll")
      html.classList.add("no-scroll")
      // navbarMain.classList.add("search-show")
    }
  }, [searchMobile])

  //navbar top close function
  const [showNavTop, setShowNavTop] = useState(true)
  const closeNavTop = () => {
    // const navTop = document.querySelector(".navbar-top")
    // navTop.classList.add("hide")
    setShowNavTop(false)
    setTimeout(() => {
      dispatch({ type: SET_TOAST_HEIGHT, payload: false })
    }, 200)
  }

  //verify email toast
  const { user_data } = useSelector((state) => state.auth)
  const cookies = parseCookies()
  const showToastVerify = cookies?.show_toast_verify

  useEffect(() => {
    if (user_data && !user_data.email_verified_at && showToastVerify) {
      dispatch({
        type: SET_TOAST,
        payload: {
          show: true,
          message: `Verify your email address. We’ve sent the link to ${user_data.email}.`,
          status: "verify",
        },
      })
      setCookie(null, "show_toast_verify", "", {
        maxAge: 30 * 24 * 60 * 60,
        path: "/",
      })
    }
  }, [])

  const [navColor, setNavColor] = useState("")

  useEffect(() => {
    let tempPath = window.location.pathname
    setNavColor(navColorProps)
    setPathname(tempPath)

    if (tempPath.match(/\/product/g)) return setNavColor("black")
    if (navColorProps) return setNavColor(navColorProps)
    switch (tempPath) {
      case "/terms-and-condition":
      case "/privacy-policy":
      case "/contact-us":
      case "/faq":
      case "/search":
        return setNavColor("black")
      default:
        window.addEventListener("scroll", (e) => {
          const secSection = document.querySelector("main section:nth-child(2)")
          if (secSection) {
            setNavColor(
              window.pageYOffset > secSection.offsetTop - 100
                ? "black"
                : "white"
            )
          } else {
            const scroll = document.documentElement.scrollTop
            setNavColor(scroll ? "black" : "white")
          }
        })
        break
    }
  }, [])

  return (
    <>
      {/* When giving .navbar-expand-*, don't forget to modify the variables on _navbar.scss */}
      <nav
        className={`
          navbar ${navTheme} transparent navbar-expand-${navExpand} ${navPosition}
          ${mobileMenu.clicked ? "mobile-menu-open" : ""}
          ${shoppingBagMenu.clicked ? "shopping-bag-menu-open" : ""}
        `}
      >
        <div
          className={`navbar-main ${navColor === "black" && showMenu ? "bg-white" : ""
            }`}
        >
          <div className="container">
            <ul className="col-left">
              <li className="nav-item-brand">
                {/* prettier-ignore */}
                <Link className="navbar-brand absolute">
                  <img src={true ? bgBlue : bgPink} className="bg-logo" alt="Background Logo" />
                  <Link to="/">
                    <img src={logoLight} className="logo-light img-fluid" alt="Logo" />
                    {/* <img src={logoDark} className="logo-dark img-fluid" alt="Logo" /> */}
                  </Link>
                </Link>
              </li>
            </ul>

            {!withoutLink && (
              <>
                {/* Mobile */}
                <ul className={`col-right d-${navExpand}-none`}>
                  <li className="nav-item">
                    <Button
                      variant="dark"
                      // className="nav-icon search-toggler"
                      className="px-2 py-1 my-3"
                      disabled={pathname === "/search"}
                      onClick={handleSearchMobile}
                    >
                      <i className={`air ai-search`} />
                    </Button>
                  </li>
                  <li className="nav-item">
                    <Button
                      variant="dark"
                      // className="nav-icon"
                      className="px-2 py-1 my-3"
                      onClick={handleShoppingBag}
                    >
                      <div className="position-relative">
                        <i className={`aib ai-handbag`} />
                        {carts?.items?.length > 0 && (
                          <div className={`qty-number`}>
                            {carts?.items?.length}
                          </div>
                        )}
                      </div>
                    </Button>
                  </li>
                  <li className="nav-item">
                    <Button
                      variant="dark"
                      className="px-2 py-1 my-3 nav-toggler inactive absolute"
                      disabled={disabledMenu}
                      onClick={handleMenuMobile}
                    >
                      <div>
                        <span className={`icon-bar top-bar`} />
                        <span className={`icon-bar middle-bar`} />{" "}
                        <span className={`icon-bar bottom-bar`} />
                      </div>
                    </Button>
                  </li>
                </ul>

                {/* Desktop */}
                <DesktopMenu
                  showMenu={showMenu}
                  navColor={navColor}
                  disabledSearch={disabledSearch}
                  handleSearch={handleSearch}
                  navExpand={navExpand}
                  handleShoppingBag={handleShoppingBag}
                  shoppingBagMenu={shoppingBagMenu}
                  disableMenu={disableMenu}
                />
                <SearchMenu handleSearch={handleSearch} search={search} />
              </>
            )}
          </div>
        </div>

        <MobileMenu
          mobileMenu={mobileMenu}
          navExpand={navExpand}
          variant="slide-right"
          handleShopMenuMobile={handleShopMenuMobile}
          disabledShopMenu={disabledShopMenu}
          handleAccountMenuMobile={handleAccountMenuMobile}
          disabledAccountMenu={disabledAccountMenu}
          handleMenuMobile={handleMenuMobile}
        />
        <BagMenu
          handleShoppingBag={handleShoppingBag}
          mobileMenu={shoppingBagMenu}
          shoppingBagMenu={shoppingBagMenu}
          variant="slide-left"
        />
        <SearchMenuMobile
          handleSearch={handleSearchMobile}
          search={searchMobile}
          navExpand={navExpand}
          variant="basic"
        />
        <div className="first-menu menu-bg" />
      </nav>
    </>
  )
}
