import router from "next/router"

const { Modal, Button } = require("components/anti")

const NotLoginModal = ({ modal, setModal, handleShoppingBag }) => {
  return (
    <Modal
      id="not-login-modal"
      className="not-login-modal"
      isShowing={modal}
      hide={() => setModal(null)}
    >
      <h1 className="text-center mb-4">How do you want to continue?</h1>
      <Button
        onClick={() => {
          setModal(null)
          handleShoppingBag()
        }}
        className="w-100 border-rounded mb-4"
        variant="primary"
        link="/auth?checkout=true"
      >
        Login
      </Button>
      <Button
        onClick={() => {
          setModal(null)
          handleShoppingBag()
        }}
        className="w-100 border-rounded mb-4"
        variant="outline-primary"
        link="/auth?tab=register&checkout=true"
      >
        Register
      </Button>
      {/* <Button
                onClick={() => {
                    setModal(null)
                    handleShoppingBag()
                    router.push("/checkout")
                }}
                className="w-100 border-rounded mb-4"
                variant="outline-primary">
                Continue as Guest
            </Button> */}
    </Modal>
  )
}

export default NotLoginModal
