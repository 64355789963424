export const replaceIpfsOrigin = (url) =>
  url?.replace("ipfs://", "https://karafuru.mypinata.cloud/ipfs/")

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const phoneRegex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const truncate = (string, length) => {
  if (string?.length <= length) return string

  let separator = "..."

  let sepLen = separator.length,
    charsToShow = length - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2)

  return (
    string?.substr(0, frontChars) +
    separator +
    string?.substr(string?.length - backChars)
  )
}

export const variantMapper1 = (option_1, option_2, options) => {
  if (option_1 && option_2) {
    const opt1 = options[0]?.values?.find((item) => item.value === option_1)?.title
    const opt2 = options[1]?.values?.find((item) => item.value === option_2)?.title
    return `${options[0]?.title}: ${opt1}, ${options[1]?.title}: ${opt2}`
  } else if (option_1 && !option_2) {
    const variant = options[0]?.values?.find((item) => item.value === option_1)?.title
    return `${options[0]?.title}: ${variant}`
  } else {
    return ""
  }
}
export const variantMapper2 = (option_1, option_2) => {
  if (option_1 && option_2) {
    return `${option_1?.title}: ${option_1?.value}, ${option_2?.title} ${option_2?.value}`
  } else if (option_1 && !option_2) {
    return `${option_1.title}: ${option_1?.value}`
  } else {
    return ""
  }
}
