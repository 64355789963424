import React, { useEffect, useLayoutEffect, useState } from "react"
import { Link, Social } from "components/anti"
import { Button } from "components/anti/buttons/buttons"
import { truncate } from "components/utils/helpers"
import { Select } from "components/anti/forms/forms"
import { useDispatch, useSelector } from "react-redux"
import Cookies from "js-cookie"
import useConnectWallet from "components/hooks/useConnectWallet"
import ConnectWalletModal from "components/global/connect-wallet-modal/connect-wallet-modal"
import { toastHandling } from "lib/utils/toast-handling"
import { SET_CURRENCY } from "lib/redux/types"
import { useRouter } from "next/router"
import { parseCookies, setCookie } from "nookies"

const listCurrency = [
  { label: "IDR", value: 0 },
  { label: "USD", value: 1 },
]
import icProfile from "assets/img/karafuru/icon/ic-profile.png"

const DesktopMenu = ({
  navColor,
  navExpand,
  handleSearch,
  disabledSearch,
  handleShoppingBag,
  showMenu,
}) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const cookies = parseCookies()
  const currencyCookies = cookies.currency
  const { account, onActivate, error } = useConnectWallet()
  const [loading, setLoading] = useState(null)
  const [modal, setModal] = useState(null)
  const { user_data } = useSelector((state) => state.auth)
  const { currency } = useSelector((state) => state.general)
  const [activeState, setActiveState] = useState("")
  const [activeSubState, setActiveSubState] = useState("")
  const [openMenu, setOpenMenu] = useState(false)
  const { carts } = useSelector((state) => state.cart)
  const [accountDropdown, setAccountDropdown] = useState(false)

  const menuShop = () => {
    setOpenMenu(!openMenu)
    setAccountDropdown(false)
  }

  useLayoutEffect(() => {
    const pathname = window.location.pathname.split("/")
    setActiveState(pathname[1])
    setActiveSubState(window.location.search)
  }, [router.asPath])

  const handleLogout = () => {
    Cookies.remove(`${process.env.INITIAL_TOKEN}access_token`)
    Cookies.remove(`${process.env.INITIAL_TOKEN}refresh_token`)
    window.location.href = "/auth?logout=success"
  }

  const onConnectWallet = async (connector) => {
    setLoading(connector)
    try {
      await onActivate(connector)
      setModal(null)
    } catch (err) {
      setLoading(null)
      return toastHandling({ message: err.message, status: "danger" })
    }
  }

  useEffect(() => {
    if (account && loading) {
      setLoading(null)
      toastHandling({
        message: "You have successfully connected your wallet",
        status: "success",
      })
    }
  }, [account, loading])

  useEffect(() => {
    if (error) {
      setLoading(null)
    }
  }, [error])

  const onChangeCurrency = (value) => {
    dispatch({ type: SET_CURRENCY, payload: value })
    setCookie(null, "currency", value.label, {
      maxAge: 30 * 24 * 60 * 60,
      path: "/",
    })
    router.reload()
  }

  return (
    <>
      <ConnectWalletModal
        modal={modal}
        setModal={setModal}
        onClick={onConnectWallet}
        loading={loading}
        isShopDetail={false}
      />
      <div
        className={`desktop-menu d-none d-${navExpand}-flex ${!showMenu ? "hide" : "show"
          }`}
      >
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <Link
              className={`nav-link text-${navColor} ${navColor == "black" ? "secondary" : "primary"
                } ${activeState === "" && "active"}`}
              activeClassName="active"
              to="/"
              target=""
            >
              Home
            </Link>
          </li>
          <li
            className="nav-item nav-shop"
          //  onClick={menuShop}
          >
            <Link
              className={`nav-link text-${navColor} ${navColor == "black" ? "secondary" : "primary"
                } ${activeState == "product" && "active"}`}
              activeClassName="active"
              to="/product"
              target=""
            >
              Shop
            </Link>
            <div className={`menu-shop`}>
              <ul>
                <li
                  className={`${activeState == "products" &&
                    activeSubState === "" &&
                    "active"
                    }`}
                >
                  <Link to="/product">All items</Link>
                </li>
                <li
                  className={`${activeSubState == "?category=3" && "active"}`}
                >
                  <Link to="/product?category=3">Clothing</Link>
                </li>
                <li
                  className={`${activeSubState == "?category=1" && "active"}`}
                >
                  <Link to="/product?category=1">Accessories</Link>
                </li>
                <li
                  className={`${activeSubState == "?category=2" && "active"}`}
                >
                  <Link to="/product?category=2">Others</Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <ul className="nav-currency mx-3">
          <li className="nav-item">
            <Select
              label=""
              id="currency"
              options={listCurrency}
              // value={listCurrency?.filter((item) => item.label === currencyCookies)[0]}
              value={currency}
              onChange={onChangeCurrency}
              className="select-currency"
            />
          </li>
        </ul>
        <ul className="navbar-nav">
          <li className="nav-item">
            {account ? (
              <Link className="nav-link nav-link-wallet px-3 py-0">
                {truncate(account, 13)}
              </Link>
            ) : (
              <Button
                variant="dark"
                onClick={() => setModal("connect-wallet-modal")}
              >
                CONNECT WALLET
              </Button>
            )}
          </li>
          <li className="nav-item ml-3 mr-4">
            <Button
              variant="dark"
              className="px-2 py-1"
              disabled={activeState === "search"}
              onClick={() => {
                handleSearch()
                setOpenMenu(false)
                setAccountDropdown(false)
              }}
            >
              <i className="air ai-search" />
            </Button>
          </li>
          <li className="nav-item mr-4">
            <Button
              variant="dark"
              className="px-2 py-1 account-button"
              link={!user_data && "/auth"}
              onClick={
                user_data &&
                (() => {
                  setAccountDropdown(!accountDropdown)
                  setOpenMenu(false)
                })
              }
            >
              <img src={icProfile} className="img-fluid ic-profile" />
              {user_data && (
                <ul className={`account-list ${accountDropdown ? "" : "hide"}`}>
                  <li className="account-item">
                    <Link to="/account/my-order">My Order</Link>
                  </li>
                  <li className="account-item">
                    <Link to="/account/address">Manage Address</Link>
                  </li>
                  <li className="account-item">
                    <Link to="/account/settings">Settings</Link>
                  </li>
                  <li
                    className="account-item text-danger"
                    onClick={handleLogout}
                  >
                    Log Out
                  </li>
                </ul>
              )}
            </Button>
          </li>
          <li className="nav-item">
            <Button
              variant="dark"
              className="px-2 py-1"
              onClick={handleShoppingBag}
            >
              <i className="aib ai-handbag" />
              {carts?.items?.length > 0 && (
                <div className="qty-number">{carts?.items?.length}</div>
              )}
            </Button>
          </li>
        </ul>
      </div>
    </>
  )
}

export default DesktopMenu
