import React from "react"
import { Link } from "components/anti"
import { useSelector } from "react-redux"
import { useRouter } from "next/router"
import arrowLeft from "assets/img/icon/ic-arrow-think-left-white.svg"
const MobileShopMenu = ({
  navExpand,
  variant,
  handleShopMenuMobile,
  disabledShopMenu,
  handleMenuMobile,
}) => {
  const router = useRouter()
  const { categories } = useSelector((state) => state.general)

  const handleRedirect = (link) => {
    router.push(link)
    handleMenuMobile()
  }

  // Data Layer
  const navDataLayer = (title) => {
    dataLayer.push({
      event: "interaction",
      eventCategory: "Button Click",
      eventAction: `${router.pathname}`, // /shop, /product
      eventLabel: `Submenu - ${title}`, // Menu - Our Story, etc.
    })
  }

  return (
    <>
      <div
        className={`mobile-shop-menu mobile-menu d-${navExpand}-none ${variant}`}
      >
        <div className="mobile-menu-content">
          <div className="container">
            <div className="btn-back-wrapper">
              <button
                className="nav-link btn-back"
                onClick={handleShopMenuMobile}
                disabled={disabledShopMenu}
              >
                <img src={arrowLeft} alt="arrow" className="mr-2" /> Shop
              </button>
            </div>
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  className={`nav-link`}
                  onClick={() => handleRedirect("/product")}
                >
                  All items
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link`}
                  onClick={() => handleRedirect("/product?category=3")}
                >
                  Clothing
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link`}
                  onClick={() => handleRedirect("/product?category=1")}
                >
                  Accessories
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link`}
                  onClick={() => handleRedirect("/product?category=2")}
                >
                  Others
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default MobileShopMenu
