import formatCurrency from "format-currency"
import CurrencyFormatter from "currency-formatter"
import moment from "moment-timezone"

export const FormatCurr = (num) => {
  if (num) {
    return num
      .toFixed(0) // always two decimal digits
      .replace(".", ",") // replace decimal point character with ,
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") // use . as a separator
  }
  return num
}

export const FormatCurrency = (num) =>
  formatCurrency(num, { format: "%s%v", symbol: "" })

export const ProductLink = (str, id) =>
  "/product/" +
  str.toLowerCase().replace("/", "").split(" ").join("-") +
  "-" +
  id

export const orderStatus = (detail) => {
  let { order_status } = detail
  let { id } = order_status

  if (id === "1") {
    return {
      status: "pending",
      alias: "Waiting Payment",
      text: `Please proceed payment before ${moment(detail?.created_at)
        .add(1, "days")
        .format("LL")} so that we can process your order.`,
      // cta: [
      //   {
      //     text: "LEARN HOW TO PAY",
      //     url: "#",
      //   },
      // ],
    }
  } else if (id === "2") {
    return {
      status: "processing",
      alias: "Processing",
      text: "Your order is currently being processed. Please wait while we’re preparing your order.",
    }
  } else if (id === "3") {
    return {
      status: "delivery",
      alias: "In Shipping",
      text: "Your order is being delivered and is currently on the way. Please wait within the stated delivery time.",
    }
  } else if (id === "4") {
    return {
      status: "completed",
      alias: "Completed",
      text: "Your order has been completed. Thank you for shopping at Karafuru Shop!",
    }
  } else if (id === "5") {
    return {
      status: "cancelled",
      alias: "Canceled",
      text: "Your order has been canceled. If you think this is a mistake, please contact us.",
    }
  } else {
    return {
      status: "default", // cancel,
      text: "",
    }
  }
}

export const optionsParse = (options, type) => {
  if (type === "categories") {
    let newData = [{ label: "All categories", value: null }]
    options.forEach((item) => {
      newData.push({ label: item.title, value: item.id })
    })
    return newData
  } else {
    let size = [{ label: "All sizes", value: null }]
    let color = [{ label: "All colors", value: null }]
    options.forEach((item) => {
      let { title, values } = item
      if (title === "Size") {
        values.forEach((item2) => {
          size.push({ label: item2.title, value: item2.value })
        })
      }
      if (title === "Color") {
        values.forEach((item2) => {
          color.push({ label: item2.title, value: item2.value })
        })
      }
    })
    return { size, color }
  }
}

// { size, color, price: state.priceOptions, }

export const parseSelect = (query, data) => {
  let sizeOptions = data.size
  let colorOptions = data.color
  let priceOptions = data.price

  let { size, color, price } = query

  let sizeSelect = { label: "All sizes", value: null }
  let colorSelect = { label: "All colors", value: null }
  let priceSelect = { label: "All prices", value: 0 }

  if (size) sizeSelect = sizeOptions.find((item) => item.value === size)
  if (color) colorSelect = colorOptions.find((item) => item.value === color)
  if (price)
    priceSelect = priceOptions.find((item) => item.value === Number(price))

  return {
    sizeSelect,
    colorSelect,
    priceSelect,
  }
}

export const errorPayment = (type) => {
  switch (type) {
    case "UNKNOWN_BANK_NETWORK_ERROR":
    case "TEMPORARY_BANK_NETWORK_ERROR":
    case "TEMPORARY_SYSTEM_ERROR":
    case "SWITCHING_NETWORK_ERROR":
      return "There has been an error when processing your payment. Please retry your payment in 1-3 hours, and if this problem persists, please contact your bank support."
    case "EXPIRED_CARD":
      return "Your card has expired. Please retry using another card, or contact your bank support."
    case "INACTIVE_CARD":
      return "Your card is inactivated. Please retry using another card, or contact your bank support."
    case "CARD_DECLINED":
      return "Your payment has been declined by your bank. Please retry using another card, or contact your bank support."
    case "PROCESSOR_ERROR":
      return "There has been an issue with your processor. Please retry your payment, and if this problem persists, please contact your bank support."
    case "INSUFFICIENT_BALANCE":
      return "There is an insufficient balance in your account. Please retry after making sure you have a sufficient balance in your account or use another card."
    case "STOLEN_CARD":
      return "The card used for this transaction is flagged as lost or stolen by the bank. Please contact your bank support."
    case "CAPTURE_AMOUNT_LIMIT_ERROR":
      return "The amount of transaction hasn't met your minimum limit or has exceeded your maximum limit. Please contact your bank support to find / manage your account limits."
    default:
      return "Sorry, your payment failed. Please retry with another card or contact your bank support."
  }
}
