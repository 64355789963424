import React from "react"
import { useRouter } from "next/router"
import { Link } from "components/anti"
import Cookies from "js-cookie"
import arrowLeft from "assets/img/icon/ic-arrow-think-left-white.svg"

const MobileAccountMenu = ({
  navExpand,
  variant,
  handleAccountMenuMobile,
  disabledAccountMenu,
  handleMenuMobile,
}) => {
  const router = useRouter()

  const handleLogout = () => {
    Cookies.remove(`${process.env.INITIAL_TOKEN}access_token`)
    Cookies.remove(`${process.env.INITIAL_TOKEN}refresh_token`)
    window.location.href = "/auth?logout=success"
  }

  const handleRedirect = (link) => {
    router.push(link)
    handleMenuMobile()
  }

  return (
    <>
      <div
        className={`mobile-account-menu mobile-menu d-${navExpand}-none ${variant}`}
      >
        <div className="mobile-menu-content">
          <div className="container">
            <div className="btn-back-wrapper">
              <button
                className="nav-link btn-back"
                onClick={handleAccountMenuMobile}
                disabled={disabledAccountMenu}
              >
                <img src={arrowLeft} alt="arrow" className="mr-2" /> My Account
              </button>
            </div>
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  activeClassName="active"
                  // to="/account/my-order"
                  onClick={() => handleRedirect(`/account/my-order`)}
                >
                  My Order
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  activeClassName="active"
                  // to="/account/address"
                  onClick={() => handleRedirect(`/account/address`)}
                >
                  Manage Address
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  activeClassName="active"
                  // to="/account/settings"
                  onClick={() => handleRedirect(`/account/settings`)}
                >
                  Settings
                </Link>
              </li>
              <li className="nav-item">
                <div
                  className="nav-link"
                  activeClassName="active"
                  // to="/logout"
                  onClick={handleLogout}
                >
                  Log Out
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default MobileAccountMenu
