import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core"
import {
    NoEthereumProviderError,
    UserRejectedRequestError as UserRejectedRequestErrorInjected
} from "@web3-react/injected-connector"
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect } from "@web3-react/walletconnect-connector"
import { injected, walletconnect } from "components/utils/connecters"
import { ErrorStateContext } from "context/error-msg-context"
import { toastHandling } from "lib/utils/toast-handling"
import { useContext, useEffect, useState } from "react"
import useEagerConnect from "./useEagerConnect"
import useInactiveListener from "./useInactiveListener"

const useConnectWallet = () => {
    const { connector, account, activate, error, library } = useWeb3React()
    const { setErrorMsg } = useContext(ErrorStateContext)
    const triedEagerConnect = useEagerConnect()

    const [activatingConnector, setActivatingConnector] = useState()
    const [isOnActivate, setIsOnActivate] = useState(false)

    useInactiveListener(!triedEagerConnect || !!activatingConnector)


    useEffect(() => {
        if (activatingConnector && activatingConnector === connector) {
            setActivatingConnector(undefined)
        }
    }, [activatingConnector, connector])

    useEffect(() => {
        if (error && isOnActivate) {
            getErrorMessage(error)
        } else {
            setErrorMsg("")
        }
    }, [error])

    const getErrorMessage = (error) => {
        if (error instanceof NoEthereumProviderError) {
            setErrorMsg(
                "No Ethereum browser extension detected. Install MetaMask on desktop or use MetaMask app browser on mobile."
            );
            toastHandling({
                message: "No Ethereum browser extension detected. Install MetaMask on desktop or use MetaMask app browser on mobile.",
                status: "danger"
            })
        } else if (error instanceof UnsupportedChainIdError) {
            setErrorMsg("You're connected to an unsupported network.");
            toastHandling({
                message: "You're connected to an unsupported network.",
                status: "danger"
            })
        } else if (
            error instanceof UserRejectedRequestErrorInjected ||
            error instanceof UserRejectedRequestErrorWalletConnect
        ) {
            setErrorMsg("Please authorize this website to access your Ethereum account.");
            toastHandling({
                message: "Please authorize this website to access your Ethereum account.",
                status: "danger"
            })
        } else if (
            error?.message &&
            error.message.includes("'wallet_requestPermissions' already pending")
        ) {
            setErrorMsg("Metamask already open")
            toastHandling({
                message: "Metamask already open",
                status: "danger"
            })
        } else {
            setErrorMsg("An unknown error occurred. Check the console for more details.");
            toastHandling({
                message: "An unknown error occurred. Check the console for more details.",
                status: "danger"
            })
        }
    };

    const onActivate = async (connector) => {
        setIsOnActivate(true)
        try {
            await activate(connector)
        } catch (error) {
            throw error
        } finally {
            setIsOnActivate(false)
        }
    }

    return { account, error, onActivate, library }
}

export default useConnectWallet