import { Button, Modal } from "components/anti"
import { injected, walletconnect } from "components/utils/connecters"
import { SET_TOAST } from "lib/redux/types"
import { useDispatch } from "react-redux"

const ConnectWalletModal = ({
  modal,
  setModal,
  onClick,
  loading,
  isShopDetail,
  hide
}) => {
  const dispatch = useDispatch()
  return (
    <Modal
      id="connect-wallet-modal"
      className="connect-wallet-modal centered"
      isShowing={modal}
      hide={hide ? () => hide() : () => setModal(null)}
    >
      <div>
        <h3>
          {isShopDetail ? "Connect wallet to purchase" : "Connect Your Wallet"}
        </h3>
        <p>
          If you are a Karafuru Genesis holders, you can connect your wallet to access & purchase "Holders Only" exclusive merchandise.
        </p>
        <Button
          variant="secondary"
          className={`w-100 mb-2 ${loading === injected ? "loading" : ""}`}
          onClick={() => onClick(injected)}
          loader={loading === injected}
        >
          MetaMask
        </Button>
        <Button
          variant="secondary"
          className={`w-100 ${loading === walletconnect ? "loading" : ""}`}
          onClick={() => onClick(walletconnect)}
          loader={loading === walletconnect}
        >
          WalletConnect
        </Button>
      </div>
    </Modal>
  )
}

export default ConnectWalletModal
